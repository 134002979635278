import React, { useState } from 'react';
import './App.css';
import './footer.css'; 
import datos from './datos.json';
import logo from './db/barr.jpg';
import './Navbar.css'; 
import { CAlert } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import banner from './db/banner.jpeg';
import banner1 from './db/banner1.jpeg';
import { CNavbar, CContainer, CNavbarBrand, CNavbarToggler, COffcanvas, COffcanvasHeader, COffcanvasTitle, CCloseButton, COffcanvasBody, CNavbarNav, CNavItem, CNavLink, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider, CForm, CFormInput, CButton } from '@coreui/react';
import { CCarousel, CCarouselItem, CImage, CCarouselCaption } from '@coreui/react';

function App() {
  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filteredResults = datos.articulos.filter((articulo) =>
      articulo.nombre.toLowerCase().includes(term)
    );
    setSearchResults(filteredResults);
  };

  const renderArticulos = () => {
    const resultados = searchTerm ? searchResults : datos.articulos;
    return (
      <div className="container">
        {resultados.map((articulo, index) => (
          <div key={index} className="card">
            <img className="pic" src={articulo.pic} alt={articulo.nombre}></img>
            <h2 className="card-title">{articulo.nombre}</h2>
            <p className="card-description">{articulo.descripcion}</p>
            <p className="card-description">{articulo.precio}</p>
            <a href={articulo.enlace} className="button" target="_blank" rel="noreferrer">Compra Online (Wallapop)</a>
            <a href={articulo.chat} className="button" target="_blank" rel="noreferrer">Pedido (Chat)</a>
          </div>
        ))}
      </div>
    );
  };



  return ( 
    <body>
      <CNavbar className="bg-body-tertiary" placement="fixed-top">
        <CContainer fluid>
          <CNavbarBrand href="#">
            <img src={logo} alt="" width="22" height="24" className="d-inline-block align-top" /> Barr Partes y Piezas
          </CNavbarBrand>
          <CNavbarToggler aria-controls="offcanvasNavbar" aria-label="Toggle navigation" onClick={() => setVisible(!visible)} />
          <COffcanvas id="offcanvasNavbar" placement="end" portal={false} visible={visible} onHide={() => setVisible(false)}>
            <COffcanvasHeader>
              <COffcanvasTitle>Menu</COffcanvasTitle>
              <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
            </COffcanvasHeader>
            <COffcanvasBody>
              <CNavbarNav>
                <CNavItem>
                  <CNavLink href="http://barr.es" target='_blank' active>
                    Home
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink href="https://es.wallapop.com/app/user/rogeliol-447914120-wzynxrl5pxj5/published" target='_blank'>Piezas Wallapop</CNavLink>
                </CNavItem>
                <CDropdown variant="nav-item" popper={false}>
                  <CDropdownToggle color="secondary">Coches</CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem href="https://bullcar.es/" target='_blank'>Bullcar Coches</CDropdownItem>
                    <CDropdownItem href="#" target='_blank'>Coches Barr</CDropdownItem>
                    <CDropdownDivider />
                    <CDropdownItem href="#">Something else here</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
                <CNavItem>
                  <CNavLink href="#" disabled>
                    Disabled
                  </CNavLink>
                </CNavItem>
              </CNavbarNav>
              <CForm className="d-flex">
                <CFormInput type="search" className="me-2" placeholder="search" value={searchTerm} onChange={handleSearchChange} />
                <CButton type="submit" color="success" variant="outline">
                  Filtrar
                </CButton>
              </CForm>
            </COffcanvasBody>
          </COffcanvas>
        </CContainer>
      </CNavbar>

      <br />

      <div className='carusel'>
        <CCarousel controls indicators>
          <CCarouselItem>
            <CImage className="d-block w-100" src={banner} alt="slide 1" />
            <CCarouselCaption className="d-none d-md-block">
              <h5>First slide label</h5>
              <p>Some representative placeholder content for the first slide.</p>
            </CCarouselCaption>
          </CCarouselItem>
          <CCarouselItem>
            <CImage className="d-block w-100" src={banner1} alt="slide 2" />
            <CCarouselCaption className="d-none d-md-block">
              <h5>Second slide label</h5>
              <p>Some representative placeholder content for the first slide.</p>
            </CCarouselCaption>
          </CCarouselItem>
        </CCarousel>
      </div>

      <div className="container">  
        {renderArticulos()}       
      </div>

      <footer className="footer">
        <div className="footer-section">
          <h3>Contacto por WhatsApp</h3>
          <p>¿Tienes alguna pregunta? ¡Contáctanos por WhatsApp!</p>
          <a href="https://wa.me/1234567890" target="_blank" rel="noreferrer">
            <img src="whatsapp-icon.png" alt="WhatsApp"></img>
          </a>
        </div>
        <div className="footer-section">
          <h3>Redes Sociales</h3>
          <div className="social-links">
            <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">Facebook</a>
            <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">Instagram</a>
            <a href="https://www.twitter.com/" target="_blank" rel="noreferrer">Twitter</a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Comentarios de la Empresa Barr</h3>
          <p>¡Déjanos tus comentarios y sugerencias!</p>
          <textarea className="comment-box" placeholder="Escribe tu comentario aquí"></textarea>
          <button className="submit-button">Enviar</button>
        </div>
        <div className="footer-section">
          <h3>Encuéntranos en el Mapa</h3>
          <iframe
            title="Ubicación de la Empresa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2918.5914113478934!2d-7.589175324991295!3d42.986879895272764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd31cd18210874cf%3A0xc0f7e28e315b6a66!2sBullcar%20Classic!5e0!3m2!1ses!2ses!4v1713651667171!5m2!1ses!2ses"
            width="400"
            height="300"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </footer>
    </body>
  );
}

export default App;
